<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Reportes de compras</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card>
            <b-row >
              <b-col sm="10">            
                <b-row>            
                  <b-col sm="12" class="pb-1">
                    <b-row>         
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                      </b-col>
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                      </b-col> 
                      <b-col sm="3" class="p-1 pb-1">
                        <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col> 
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="2" class="p-1">
                <b-row>
                  <b-col sm="12">
                    <b-button variant="outline-dark" @click="loadByFilter()" size="sm" class="pull-right">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>                       
                </b-row>
              </b-col>
            </b-row>   
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">
        <b-tab title="IVA Compras">                   
          <b-col sm="12" class="mr-0 pr-0"  v-if="table.items.length && !table.preload">
            <b-link>
              <export-excel
                class = 'pull-right mt-2 mb-2'
                :data = "arr.export"
                worksheet = "IVA Compras"
                name = "taxes-purchase.xls">
                Exportar Datos
              </export-excel>
            </b-link>
          </b-col>

          <b-table-simple hover small caption-top responsive v-if="table.items.length && !table.preload">
            <b-thead head-variant="dark">                    
              <b-tr>
                <b-th width="13%" class="text-center">Comprobante</b-th>
                <b-th width="6%" class="text-center">Fecha</b-th>
                <b-th width="6%" class="text-center">Vencimiento</b-th>
                <b-th width="6%" class="text-center">Imputación</b-th>
                <b-th class="text-left">Proveedor</b-th>
                <b-th width="6%" class="text-right">Neto</b-th>
                <b-th width="6%" class="text-right" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="'iva_conditions_' + indexI">{{itemI.label}}</b-th>
                <b-th width="6%" class="text-right" v-for="(itemP, indexP) in arr.columns.perceptions" :key="'perceptions_' + indexP">{{itemP.label}}</b-th>
                <b-th width="6%" class="text-right">Total</b-th>
              </b-tr>
            </b-thead>   
            <b-tbody>
              <b-tr  v-for="item in table.items" :key="item.id">
                <b-td class="text-center">
                  <div v-if="item.type_voucher && item.point_sale">
                    <b-badge variant="dark" v-if="item.points_sales">
                      {{item.points_sales.point_sale}} - {{item.points_sales.name}}
                    </b-badge> <br>
                    {{item.type_voucher.name}}
                    {{item.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                  </div>
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date).format('DD/MM/YYYY')}}
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date_expiration).format('DD/MM/YYYY')}}
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date_imputation).format('DD/MM/YYYY')}}
                </b-td>

                <b-td>
                  <span style="font-size: 9px">{{item.supplier.data_fiscal.documents_number}} / {{item.supplier.data_fiscal.fiscal_condition.name}}</span><br>
                  {{item.supplier.name}}
                </b-td>

                <b-td class="text-right align-middle">
                  <div v-if="item.currency">
                    <div style="color:green;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: item.currency.code}).format(item.amount_net)}}</b>
                    </div>
                    <div style="color:red;" v-else>
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_net)}})</b>
                    </div>
                  </div>
                </b-td>

                <b-td class="text-right align-middle" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="'iva_conditions_' + indexI">
                  <div v-if="item.currency">
                    <div style="color:green;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}}</b>
                    </div>
                    <div style="color:red;" v-else>
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}})</b>
                    </div>
                  </div>
                </b-td>

                <b-td class="text-right align-middle" v-for="(itemP, indexP) in arr.columns.perceptions" :key="'perceptions_' + indexP">
                  <div v-if="item.currency">
                    <div style="color:green;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: item.currency.code}).format(item['amount_perceptions_' + itemP.label])}}</b>
                    </div>
                    <div style="color:red;" v-else>
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_perceptions_' + itemP.label])}})</b>
                    </div>
                  </div>
                </b-td>

                <b-td class="text-right align-middle">
                  <div v-if="item.currency">
                    <div style="color:green;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency', currency: item.currency.code}).format(item.amount_total)}}</b>
                    </div>
                    <div style="color:red;" v-else>
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}})</b>
                    </div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="badge-dark" colspan="5"></b-td>

                <b-td width="6%" class="text-right align-middle badge-light">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.total_net)}}</b>
                </b-td>

                <b-td width="6%" class="text-right align-middle badge-light" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="'iva_conditions_' + indexI">
                  <b style="font-size:15px;" v-if="(itemI.total_iva > 0)">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_iva)}}</b>
                  <b style="font-size:15px;" v-else>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_net)}}</b>                      
                </b-td>

                <b-td width="6%" class="text-right align-middle badge-light" v-for="(itemP, indexP) in arr.columns.perceptions" :key="'perceptions_' + indexP">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemP.total)}}</b>           
                </b-td>

                <b-td width="6%" class="text-right align-middle badge-light">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.total)}}</b>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>  
              
          <div class="text-center" v-if="table.preload">
            <b-spinner label="Spinning"></b-spinner>
          </div>
          
          <b-alert v-if="!table.items.length && !table.preload" variant="warning" show>No se encontraron registros</b-alert>
          
        </b-tab>  
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.COMPRAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },  
        table : {
          items: [],          
          filter: null,
          preload: true,
        },
        arr: {
          filters : {
            suppliers: [],
            points_sales: [],
          },
          columns : {
            iva_conditions: [],
            perceptions: [],
          }
        },
        filters: {
          date_start: '',
          date_end: '',
          suppliers: null,
          points_sales: null,
          paginate: false,
        },
        totals: {
          total: 0,
          total_net: 0,
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()
      
      setTimeout(()=>{
        this.loadByFilter()
      },200)
    },
    computed: {
      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },
    },  
    methods: {
      filterLoad() {
        this.filterLoadSuppliers()
        this.filterLoadPointSale()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
        this.getIvaConditions()
        this.getPerceptions()
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {            
            this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      getIvaConditions() {
        var result = serviceAPI.obtenerIvaCondition()
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {            
            this.arr.columns.iva_conditions.push({ code: element.id, label: element.name, total_net: 0, total_iva: 0 })
          });          
        })
      },
      getPerceptions() {
        var result = serviceAPI.obtenerPercepciones()
        result.then((response) => {
          var data = response.data

          data.forEach(element => {                            
            this.arr.columns.perceptions.push({ code: element.id, label: element.name, total: 0 })
          });          
        })   
      },
      loadByFilter() {
        this.table.preload = true

        var result = serviceAPI.filtrarComprobante(this.filters)

        result.then((response) => {          
          var data = response.data
          this.table.items = data          

          data.forEach(element => {
            if(element.net.length){
              element['amount_net'] = 0
            }

            this.arr.columns.iva_conditions.forEach(elementIva => {
              element['amount_iva_' + elementIva.label] = 0              
            
              if(element.net.length){
                element.net.forEach(elementNet => {
                  if(elementNet.iva_conditions_id == elementIva.code){
                    if(elementNet.amount_iva > 0){
                      element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementNet.amount_iva)
                      element['amount_net'] = element['amount_net'] + parseFloat(elementNet.amount_net)
                                   
                      if(element.type_voucher.type_balance == '-'){  
                        elementIva.total_iva = elementIva.total_iva - parseFloat(elementNet.amount_iva)
                      } else {
                        elementIva.total_iva = elementIva.total_iva + parseFloat(elementNet.amount_iva)
                      }

                      if(element.type_voucher.type_balance == '-'){
                        this.totals.total_net = this.totals.total_net - parseFloat(elementNet.amount_net)
                      } else {
                        this.totals.total_net = this.totals.total_net + parseFloat(elementNet.amount_net)
                      }
                    } else {
                      element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementNet.amount_net)

                      if(element.type_voucher.type_balance == '-'){
                        elementIva.total_net = elementIva.total_net - parseFloat(elementNet.amount_net)
                      } else {
                        elementIva.total_net = elementIva.total_net + parseFloat(elementNet.amount_net)
                      }
                    }
                  }
                });
              }
            });

            if(!element.net.length){
              this.totals.total_net = this.totals.total_net + parseFloat(element.amount_net)
            }

            this.arr.columns.perceptions.forEach(elementPerceptions => {
              element['amount_perceptions_' + elementPerceptions.label] = 0

              element.perceptions.forEach(elementPerceptionsDet => {               
                if(elementPerceptionsDet.perceptions_id == elementPerceptions.code){
                  element['amount_perceptions_' + elementPerceptions.label] = element['amount_perceptions_' + elementPerceptions.label] + parseFloat(elementPerceptionsDet.amount_total)

                  if(element.type_voucher.type_balance == '-'){
                    elementPerceptions.total = elementPerceptions.total - parseFloat(elementPerceptionsDet.amount_total)
                  } else {
                    elementPerceptions.total = elementPerceptions.total + parseFloat(elementPerceptionsDet.amount_total)
                  }
                }
              });
            });

            if(element.type_voucher.type_balance == '-'){
              this.totals.total = this.totals.total - parseFloat(element.amount_total)
            } else {
              this.totals.total = this.totals.total + parseFloat(element.amount_total)
            }            
          });

          this.table.preload = false
          this.prepareExport(data)
        })
        .catch(error => {
          this.table.preload = false
          this.$awn.alert(Error.showError(error))
        });
      },
      // EXPORT
      prepareExport(data) {             
        this.arr.export = []
        data.forEach(element => {      
          var suppliersName = ""          
          var suppliersCUIT = ""          
          var suppliersCondFiscal = ""          
          
          var typeVoucherName = ""

          if(element.supplier) {
            suppliersName = element.supplier.name            
            suppliersCUIT = element.supplier.data_fiscal.documents_number
            suppliersCondFiscal = element.supplier.data_fiscal.fiscal_condition.name
          }       

          if(element.type_voucher) {
            typeVoucherName = element.type_voucher.name                        
          }

          var arrExport = []

          arrExport["date"] = element.date,      
          arrExport["date_expiration"] = element.date_expiration,
          arrExport["date_imputation"] = element.date_imputation,    
          arrExport["point_sale_imputation"] = element.points_sales.point_sale,      
          arrExport["typeVoucherName"] = typeVoucherName,
          arrExport["letter"] = element.letter,
          arrExport["point_sale"] = element.point_sale,
          arrExport["number"] = element.number,
          arrExport["suppliersName"] = suppliersName,
          arrExport["suppliersCUIT"] = suppliersCUIT,
          arrExport["suppliersCondFiscal"] = suppliersCondFiscal,

          arrExport['amount_net'] = element.amount_net

          this.arr.columns.iva_conditions.forEach(elementIva => {
            arrExport['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label]
          });

          this.arr.columns.perceptions.forEach(elementPerceptions => {
            arrExport['amount_perceptions_' + elementPerceptions.label] = element['amount_perceptions_' + elementPerceptions.label]
          });

          arrExport["amount_total"] = element.amount_total,

          this.arr.export.push(arrExport)
        });
      },
    }    
  }
</script>